import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import TeamCard from '../components/team-card'
import './about.css'

const About = (props) => {
  return (
    <div className="about-container1">
      <Helmet>
        <title>About - Apprison</title>
        <meta property="og:title" content="About - Apprison" />
      </Helmet>
      <div className="about-banner">
        <header data-thq="thq-navbar" className="about-navbar-interactive">
          <Link to="/" className="about-navlink1">
            <img
              alt="logo"
              src="/apprisonlogo8x8-1500h.png"
              className="about-image1"
            />
          </Link>
          <div data-thq="thq-navbar-nav" className="about-desktop-menu">
            <nav className="about-links1">
              <a href="#about" className="about-link10">
                About
              </a>
              <a href="#features" className="about-link11">
                Features
              </a>
              <a href="#pricing" className="about-link12">
                Pricing
              </a>
              <span className="about-text10">Team</span>
              <Link to="/blog" className="about-navlink2">
                Blog
              </Link>
            </nav>
          </div>
          <div data-thq="thq-burger-menu" className="about-burger-menu">
            <svg viewBox="0 0 1024 1024" className="about-icon10">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="about-mobile-menu">
            <div className="about-nav1">
              <div className="about-top">
                <img
                  alt="image"
                  src="/apprisonlogo8x8-1500h.png"
                  className="about-logo"
                />
                <div data-thq="thq-close-menu" className="about-close-menu">
                  <svg viewBox="0 0 1024 1024" className="about-icon12">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="about-links2">
                <a href="#about" className="about-link13">
                  About
                </a>
                <a href="#features" className="about-link14">
                  Features
                </a>
                <a href="#pricing" className="about-link15">
                  Pricing
                </a>
                <span className="about-text11">Team</span>
                <Link to="/blog" className="about-navlink3">
                  Blog
                </Link>
              </nav>
              <a
                href="https://play.google.com/store/apps/details?id=com.codenicely.apprison.app.prison"
                target="_blank"
                rel="noreferrer noopener"
                className="about-link16"
              >
                <img
                  alt="image"
                  src="/google-play-badge-300h.png"
                  className="about-image2"
                />
              </a>
              <div className="about-icon-group1">
                <a
                  href="https://twitter.com/digitalwellbei4"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="about-icon14"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/apprison.digitalwellbeing/?hl=en"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="about-icon16"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/apprison4u/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <svg
                    viewBox="0 0 602.2582857142856 1024"
                    className="about-icon18"
                  >
                    <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </header>
        <h1 className="about-text12">ABOUT NINELIVES TECHNOLOGY</h1>
      </div>
      <div className="about-container2">
        <img
          alt="image"
          src="/apprisonlogo8x8-1500h.png"
          className="about-image3"
        />
        <span className="about-text13">
          NineLives Tech, a pioneer in the realm of digital wellbeing solutions,
          catering to the modern challenges posed by the ever-evolving digital
          landscape. Our suite of sophisticated smartphone applications is
          meticulously engineered to facilitate a harmonious equilibrium between
          the digital realm and personal well-being.
        </span>
        <span className="about-text14">
          At NineLives Tech, we recognize the transformative potential of
          technology as well as its potential pitfalls. Our overarching mission
          is to equip individuals with the tools they need to navigate the
          digital domain with intentionality and mindfulness. Our suite of
          smartphone apps encompasses a spectrum of digital wellness facets,
          encompassing meticulous screen-time regulation, cognitive rejuvenation
          through mindfulness, and the optimization of sleep patterns for
          holistic well-being.
        </span>
      </div>
      <section className="about-container3">
        <h1 className="about-text15">THE TEAM</h1>
        <div className="about-container4">
          <TeamCard
            name="Jagrit Surisetti"
            role="FOUNDER"
            quote="Technologist, Digital Wellness Enthusiast, Entrepreneur, Musician"
            pictureSrc="/jagritsurisetti_cu-300h.jpg"
            rootClassName="team-cardroot-class-name3"
          ></TeamCard>
          <TeamCard
            name="Jayesh Surisetti"
            role="CO-FOUNDER"
            quote="HR Professional, Start-up guy, Blogger"
            pictureSrc="/chg3t4dn_400x400-300h.jpg"
            rootClassName="team-cardroot-class-name1"
          ></TeamCard>
          <TeamCard
            name="Dr. Jawahar Surisetti"
            role="INVESTOR"
            quote="TED Speaker, Futurist , Advisor to the Government, Start Up Mentor, psychologist, Bestselling Author"
            pictureSrc="/dadp-300h.jpg"
            rootClassName="team-cardroot-class-name4"
          ></TeamCard>
        </div>
      </section>
      <div className="about-container5">
        <h1 className="about-text16">OUR PARTNERS</h1>
        <div className="about-container6">
          <img
            alt="image"
            src="/Partners/zoho-200h.png"
            className="about-image4"
          />
          <img
            alt="image"
            src="/Partners/rp-200h.png"
            className="about-image5"
          />
        </div>
      </div>
      <footer className="about-footer">
        <div className="about-container7">
          <Link to="/" className="about-navlink4">
            <img
              alt="logo"
              src="/apprisonlogo8x8-1500h.png"
              className="about-image6"
            />
          </Link>
          <nav className="about-nav2">
            <a href="#about" className="about-link20">
              About
            </a>
            <a href="#features" className="about-link21">
              Features
            </a>
            <a href="#pricing" className="about-link22">
              Pricing
            </a>
            <span className="about-text17">Team</span>
            <span className="about-text18">Blog</span>
          </nav>
        </div>
        <div className="about-separator"></div>
        <div className="about-container8">
          <span className="about-text19">info@apprison.app</span>
          <div className="about-icon-group2">
            <a
              href="https://twitter.com/digitalwellbei4"
              target="_blank"
              rel="noreferrer noopener"
              className="about-link23"
            >
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="about-icon20"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/apprison.digitalwellbeing/?hl=en"
              target="_blank"
              rel="noreferrer noopener"
              className="about-link24"
            >
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="about-icon22"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/apprison4u/"
              target="_blank"
              rel="noreferrer noopener"
              className="about-link25"
            >
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="about-icon24"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default About
